<template>
  <main>
    <content-page-header>
      <div class="hdg-tab">
        <common-page-title title="アカウント情報" />
        <tab-nation v-if="!state.verification" now-page="3" />
        <tab-nation v-else now-page="4" />
      </div>
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <license-infomation license-type="premium" />
        <div class="apply-register-panel">
          <div class="border-t border-t--decoration">
            <section>
              <common-block-title
                title="ユーザー情報"
                sub-title=""
                class-list="ja"
              />
              <form id="applyRegisterForm" class="apply-register-form">
                <label class="form-function">
                  <div
                    class="form-input-label"
                    :class="state.verification ? 'pt-0' : ''"
                  >
                    貴社名
                  </div>
                  <div class="w-full">
                    <input
                      v-if="!state.verification"
                      type="text"
                      placeholder="会社名"
                      v-model="state.registerUser.company_name"
                    />
                    <p v-else>{{ state.registerUser.company_name }}</p>
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.company_name"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                  </div>
                </label>
                <label class="form-function">
                  <div
                    class="form-input-label"
                    :class="state.verification ? 'pt-0' : ''"
                  >
                    お名前<sup class="type-required">*</sup>
                  </div>
                  <div class="w-full">
                    <input
                      v-if="!state.verification"
                      type="text"
                      placeholder="お名前"
                      v-model="state.registerUser.name"
                    />
                    <p v-else>{{ state.registerUser.name }}</p>
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.name"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                  </div> </label
                ><label class="form-function">
                  <div
                    class="form-input-label"
                    :class="state.verification ? 'pt-0' : ''"
                  >
                    お名前(カナ)<sup class="type-required">*</sup>
                  </div>
                  <div class="w-full">
                    <input
                      v-if="!state.verification"
                      type="text"
                      placeholder="お名前(カナ)"
                      v-model="state.registerUser.name_kana"
                    />
                    <p v-else>{{ state.registerUser.name_kana }}</p>
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.name_kana"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                  </div>
                </label>
                <label class="form-function items-baseline">
                  <div
                    class="form-input-label"
                    :class="state.verification ? 'pt-0' : ''"
                  >
                    メールアドレス<sup class="type-required">*</sup>
                  </div>
                  <div
                    class="w-full with-iicon"
                    :class="state.adressCheck ? 'is-active' : ''"
                  >
                    {{ state.registerUser.email }}
                  </div>
                </label>
                <label class="form-function">
                  <div
                    class="form-input-label"
                    :class="state.verification ? 'pt-0' : ''"
                  >
                    パスワード<sup class="type-required">*</sup>
                  </div>
                  <div class="w-full with-icon">
                    <template v-if="!state.verification">
                      <input
                        :type="state.passwordCheck ? 'text' : 'password'"
                        placeholder="パスワード"
                        v-model="state.registerUser.password"
                      />
                      <span
                        class="input-icon"
                        @click="state.passwordCheck = !state.passwordCheck"
                      >
                        <icon
                          v-if="!state.passwordCheck"
                          name="input-eye-icon"
                          class="icon"
                        />
                        <icon
                          v-else
                          name="input-eye-icon-active"
                          class="icon"
                        />
                      </span>
                    </template>
                    <p v-else>
                      {{ passwordVerification(state.registerUser.password) }}
                    </p>
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.password"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                  </div>
                </label>
                <label class="form-function items-baseline">
                  <div class="form-input-label">
                    職種<sup class="type-required">*</sup>
                  </div>
                  <div class="w-full">
                    <el-select
                      class="input-border"
                      v-if="!state.verification"
                      placeholder="職種を選択してください"
                      v-model="state.registerUser.industry_type_id"
                    >
                      <el-option
                        v-for="item in state.occupation"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                    <p v-else>
                      {{
                        state.occupation[
                          state.registerUser.industry_type_id - 1
                        ].name
                      }}
                    </p>
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.industry_type_id"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                  </div>
                </label>

                <!-- <label class="form-function border-t border-t--decoration">
                  <div class="form-input-label">クーポンコード</div>
                  <div class="w-full">
                    <input
                      v-if="!state.verification"
                      type="text"
                      placeholder="クーポンコード"
                      v-model="state.registerUser.coupon"
                    />
                    <p v-else>{{ state.registerUser.coupon }}</p>
                  </div>
                </label> -->

                <template v-if="!state.verification">
                  <div class="border-t border-t--decoration">
                    <div class="terms-of-service-content">
                      <p class="title">ジッセン!DX サービス利用規約</p>
                      <p class="text">
                        ジッセン!DXサービス利用規約（以下、「本利用規約」といいます）は、株式会社アイレップ（以下、「当社」といいます）指定の方法により、当社が提供する本件サービス（第１条において定義します）を利用する者（以下、「本件利用者」といいます）と当社との間に適用されます。<br />
                        <br />
                        第1条	（用語の定義）<br />
                        本利用規約において使用する用語の定義は次の各号に定めるとおりとします。<br />
                        （1）	「本件サービス」とは、当社が提供するDX・デジタルマーケティング専門eラーニングサービスサービス「ジッセン!DX」（<a href="https://www.jissendx.com" target="_blank">https://www.jissendx.com</a>）をいいます。<br />
                        （2）	「本件利用者」とは、当社所定の方法を経て、本件サービスの利用登録を行った法人又は個人をいいます。<br />
                        （3）	「本件利用者ID」とは、本件利用者毎に付与される本件サービスを利用するためのID及びパスワードをいいます。<br />
                        （4）	「本件コンテンツ」とは、当社が本件利用者に対して使用を許諾し提供する本件サービスのウェブサイト及びテキスト、画像、映像、プログラム、データ、その他のコンテンツ等をいいます。<br />
                        （5）	「本件サービス利用料」とは、本件サービスを利用するための利用料をいいます。<br />
                        （6）	「本件決済手段」とは、本件サービス利用料の支払いに際して当社が指定する決済手段をいいます。<br />
                        <br />
                        第2条	（本利用規約）<br />
                        1.	本件利用者は、当社が指定する情報（以下「契約情報」といいます。）を記入し、本件サービスの利用申込みを行うものとし、本件サービスへの申込を行った時点で当社は、本件利用者が本利用規約の内容に同意しているとみなします。<br />
                        2.	当社は、15日間の予告期間をおいて、本件利用者の登録情報記載の連絡先への電子メール等による通知、『<a href="https://www.jissendx.com" target="_blank">https://www.jissendx.com</a>』若しくはその下層において表示されるウェブサイト上への掲載、その他当社が適当と認める方法によって通知することにより、本利用規約を改定することができるものとします。変更後の内容は、当社が通知を発信した日又は本項第一文記載のウェブサイト上に掲載が開始された日から15日以内に本件利用者が異議を述べなかった場合、又は本件利用者が本利用規約の変更後に本件サービスの利用を継続した場合、本利用規約の変更に同意したものとみなし、変更後の新利用規約を適用するものとします。<br />
                        <br />
                        第3条	（利用登録）<br />
                        1.	本件サービスの利用登録を行う者は、次の各号に定める要件の全てを満たすものとします。<br />
                        （1）	未成年又はその他の制限行為能力者ではないこと（本号は法人の場合は適用されません）、但し親権者または法定代理人の同意がある場合を除く<br />
                        （2）	電子メールアドレスを保有していること<br />
                        （3）	本件決済手段の利用が可能であること<br />
                        （4）	申込者が、個人の場合は本人であること、法人の場合は対外的な契約締結権限を有する者であること<br />
                        2.	利用登録を行う者及び本件利用者は、登録情報について虚偽・不足・事実と相違ないこと及びその内容の最新性について保証するものとします。<br />
                        3.	当社は、本件利用者から取得した個人情報について、別途定める「プライバシーポリシー」（URL:<a href="https://www.irep.co.jp/policy/" target="_blank">https://www.irep.co.jp/policy/</a>）に従って取り扱うものとします。<br />
                        4.	本件利用者が本件サービスから退会を希望する場合は、当社所定の手続きを経るものとします。<br />
                        <br />
                        第４条	（本件利用者IDの管理）<br />
                        1.	当社は、本件サービスの利用に必要な場合、本件利用者に対して本件利用者IDを発行します。<br />
                        2.	本件利用者IDは、当社より承認を受けた本件利用者に限り利用することができるものとします。<br />
                        3.	本件利用者は、本件利用者IDを善良なる管理者の注意をもって取扱い、第三者に貸与又は譲渡し、その他これらに類する行為を行ってはならないものとします。<br />
                        4.	当社は、本件利用者に帰責があるか否かを問わず、本件利用者IDの使用、管理等に関連して発生した不利益又は損害（本件利用者IDの紛失、第三者による利用、不正アクセス被害等に起因する不利益又は損害を想定しますが、これに限りません）について、当社に故意又は重過失がある場合を除き、一切責任を負いません。<br />
                        5.	本件利用者が、法人である場合において、本件利用者が異動、退社等の理由により、本件利用者IDの利用資格を喪失した場合、直ちに本件利用者IDの削除を当社に依頼するものとします。<br />
                        6.	本件利用者による本件サービスの利用が終了した場合、本件利用者が本利用規約に違反し又はその恐れがある場合、当社は本件利用者に事前に通知することなく本件利用者IDの利用停止又は削除措置を実施することができるものとします。<br />
                        <br />
                        第５条	（本件サービスの利用）<br />
                        1.	本件サービスは、当社より本件サービスの利用許諾を受けた本件利用者に限り、その許諾の範囲内で利用することができます。本件利用者は本件サービスを個人による視聴以外の方法（複数人で共同して視聴することを想定しますが、これに限りません）で本件サービスを利用してはならないものとします。<br />
                        2.	本件サービスの利用のために必要な機器、端末、通信回線その他の通信環境等（以下、「利用機器等」という）の準備及び維持は、本件利用者が自らの費用と責任で行うものとします。<br />
                        3.	本件利用者は、本件サービスについて問い合わせをする場合は、当社が定める方法にしたがって行うものとします。但し、当社は別途の定めがある場合を除き、当該問合せに回答する義務を負わないものとします。<br />
                        <br />
                        第６条	（本件サービスの変更）<br />
                        当社は、本件利用者に通知するのみで、本件サービスの内容の追加、廃止、その他の変更をすることができるものとします。なお、当社は、本条に基づき当社が行った措置に基づき本件利用者に生じた損害について一切の責任を負いません。<br />
                        <br />
                        第７条	（本件サービス提供の停止等）<br />
                        当社は、次の各号の一に該当する事由が生じたときは、本件利用者に対して何ら通知又は補償を行うことなく、本件サービスの全部又は一部の実施を停止又は中断することができるものとします。<br />
                        （1）	本件サービスの実施のために必要となる設備の点検、修理、データ更新、保守等を行うとき<br />
                        （2）	電気通信事業者が電気通信サービスの提供を停止又は中断したとき<br />
                        （3）	当事者の責に帰さない火災若しくは停電又は天災地変等の不可抗力が生じたとき<br />
                        （4）	前各号のほか、当社が本件サービスの提供が困難と判断する、やむを得ない事態が発生したとき<br />
                        <br />
                        第８条	（本件サービス利用料）<br />
                        1.	本件利用者は、本件サービス利用料について本件決済手段を用いて支払うものとします。<br />
                        2.	本件利用者は、本件サービス利用料の決済において、第3条第1項第1号但書に該当する場合を除き、自らの名義以外の本件決済手段を用いてはならないものとします。<br />
                        3.	利用登録日（本件サービス利用料の初月支払完了日）より１か月間を本件サービス利用期間とし、２か月目以降は、初月支払完了日と同日を当月の本件サービス利用料の支払期日、当該支払期日より１か月間を本件サービス利用期間とします。なお、初月支払完了日と同日が存在しない月のみ、本サービス利用期間の遅い月の末日を支払期日とします。<br />
                        4.	当社は、当社の判断に基づき、特定または不特定の本件利用者に対してクーポン等を配布し、クーポン等の受領者は本件サービス料の割引等特別な対応を受けられる場合があります。<br />
                        5.	当社は、理由の如何を問わず、決済が完了し又は受領した本件サービス利用料の払い戻しは行わないものとします。また、本件決済手段を第三者に利用されたことにより発生した本件利用者の不利益及び損害については、当社に故意又は重過失が認められる場合を除き、一切責任を負わないものとします。<br />
                        6.	本件利用者が､を所定の本件サービス利用料の支払期日が過ぎてもなお履行しない場合、本件利用者は、所定の支払期日の翌日から支払日の前日までの日数に年率3％の利率で計算した金額を遅延損害金として、本件サービス利用料その他の債務と一括して、当社が指定する期日までに当社の指定する方法により支払うものとします。<br />
                        <br />
                        第９条	（利用期間）<br />
                        1.	利用者が、毎月の支払期日までに次項で定める解約手続きを行わない場合、本契約は自動的に更新されます。<br />
                        2.	本件利用者が本件サービスの利用申込みを解約しようとするときは、当社所定の方法により当社に通知するものとします。当該通知の当社へ到達日から起算する最短で到来する支払期日の前日をもって、本件サービスの利用申込みが解約されるものとします。<br />
                        <br />
                        第10条	（禁止行為）<br />
                        1.	本件利用者は、本件サービスの利用にあたり、次の各号のいずれかに該当する行為をしてはならないものとします。<br />
                        （1）	当社又は本件サービスの他の利用者若しくは第三者の権利（知的財産権、肖像権、プライバシーの権利を想定しますがこれに限りません）を侵害する行為<br />
                        （2）	当社又は本件サービスの他の利用者若しくは第三者の利益を直接又は間接的に侵害する行為<br />
                        （3）	本件コンテンツに付与されたウォーターマーク等の権利保護技術を削除、改変又は回避する行為<br />
                        （4）	当社が許諾する方法以外で本件コンテンツをダウンロード、キャプチャ、保存等をする行為<br />
                        （5）	本件コンテンツを複製、修正、リバースエンジニアリング、逆アセンブル、翻案する行為<br />
                        （6）	本件コンテンツを他のウェブサイトのフレーム等に表示する行為<br />
                        （7）	営業活動又は営利を目的として本件サービスを利用する行為<br />
                        （8）	犯罪に関連する行為又は公序良俗に反する行為<br />
                        （9）	法令、規則、ガイドライン、業界団体の内部規則等（以下、「関連法令等」という）又は本利用規約に違反する行為<br />
                        （10）	当社による本件サービスの運営を妨害するおそれのある行為<br />
                        （11）	前各号のいずれかに該当する行為を助長又は誘引する行為<br />
                        （12）	その他、当社が不適切と判断する行為<br />
                        2.	本件利用者は、本サービスの利用期間中及び利用終了後３年間において、本件サービスの全部若しくは一部を用い、又は本件サービスに類似したサービスを第三者に提供し又は販売してはならないものとします。<br />
                        3.	当社は、本件サービスにおける本件利用者の情報の送信行為が前項各号のいずれかに該当し、又は該当する恐れがあると判断した場合、本件利用者に事前に通知することなく、当該情報の全部又は一部を削除することができるものとします。当社は、本項に基づき当社が実施した措置により本件利用者に発生した損害又は不利益について一切の責任を負わないものとします。<br />
                        <br />
                        第11条	（登録取消等）<br />
                        当社は、本件利用者が次の各号いずれかの事由に該当する場合は、事前に通知又は催告することなく、当該本件利用者について本件サービスの利用を一時的に停止し、又は本件サービスの利用登録を取消すことができるものとします。<br />
                        （1）	本利用規約のいずれかの条項に違反した場合<br />
                        （2）	登録情報に虚偽の事実があることが判明した場合<br />
                        （3）	当社、他の利用者、その他の第三者に損害を生じさせる恐れのある目的又は方法で本件サービスを利用した、又は利用しようとした場合<br />
                        （4）	手段の如何を問わず、本件サービスの運営を妨害した場合<br />
                        （5）	支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合<br />
                        （6）	自ら振出し、若しくは引き受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けた場合<br />
                        （7）	差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合<br />
                        （8）	租税公課の滞納処分を受けた場合<br />
                        （9）	死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合<br />
                        （10）	6か月以上本件サービスの利用がなく、当社からの連絡に対して応当がない場合<br />
                        （11）	第３条第1項各号に該当する場合<br />
                        （12）	第１４条に定める表明保証の内容が正確または真実でないことが判明した場合<br />
                        （13）	その他、当社が本件利用者としての利用継続が適当ではないと判断した場合<br />
                        2.	前項各号のいずれかの事由に該当した場合、本件利用者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払いを行わなければなりません。<br />
                        3.	当社は、本条に基づき当社が行った行為により本件利用者に生じた損害について一切の責任を負いません。<br />
                        <br />
                        第12条	（権利の帰属）<br />
                        1.	本件コンテンツの著作権及びその他一切の権利は、当社又は当社が指定する第三者に帰属します。本件利用者は別途当社により明示的に許諾されている場合を除き、いかなる形態においても本件コンテンツの全部又は一部を複製、改変し又は第三者に対する提供し、開示若しくは使用の許諾、その他の処分をしてはならないものとします。<br />
                        2.	本サービスの利用において、本件利用者が投稿又は送信等を行ったテキスト、画像、映像、プログラム、データ、その他のコンテンツ等（以下、「投稿コンテンツ等」という）は、当社において無償で何らの制約なくして利用（複製、複写、改変、第三者への利用許諾等を含みますが、これに限りません）することができるものとします。また本件利用者は、当該投稿コンテンツ等に対して著作者人格権等の権利の行使を行わないものとします。<br />
                        <br />
                        第13条	（保証の否認及び免責）<br />
                        （1）	当社は、本件サービスを現状有姿にて提供し、その最新性・完全性・正確性・有用性・安全性等について如何なる保証も行いません。<br />
                        （2）	本件利用者は、本件サービスが関連法令等に違反するか否かを自ら責任と費用により調査するものとし、当社は本件利用者による本件サービスの利用が関連法令等に適合することについて何ら保証を行いません。<br />
                        （3）	本件サービスの利用に関連して、本件利用者と他の利用者又は第三者の間に紛争等が発生した場合、本件利用者は自らの費用と責任において解決するものとし、当社はかかる事項について一切の責任を負いません。<br />
                        （4）	本件利用者は、本件サービスを自らの責任において利用するものとし、本件サービスに関連して本件利用者の利用機器等に故障、損傷等が発生した場合、投稿コンテンツ等が削除され又は消失した場合、本件サービスが提供する情報（本件サービスに関連して提供される他の利用者及び第三者が提供する情報を含むものとします）に基づいた結果、本件利用者又は第三者に不利益又は損害が発生した場合でも、当社は一切の補償をいたしません。<br />
                        （5）	当社は、本利用規約において明示的に規定されている場合を除き、如何なる間接的、特別、結果的損害について補償をいたしません。<br />
                        （6）	当社は、本件コンテンツの内容 につき如何なる保証も行うものではありません。当社は本件コンテンツの内容について、法令への適合性、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。また、当社は、本件コンテンツに関連して本件コンテンツの聴講者が被った損害について、一切賠償の責任を負いません。<br />
                        <br />
                        第14条	（反社会的勢力との関係断絶）<br />
                        1.	利用者は、自己又は以下の各号に該当する者が反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者及びこれらと密接な関係を有する者を意味しますする。以下同じ。）である又はその恐れがあるときは、弁護士又は警察等が反社会的勢力等ではないと表明保証した場合、または反社会的勢力等ではないと当事者が同意をした場合を除き、相手方に書面又は電子メールで通知することにより、直ちに利用契約及び利用者が利用契約に関連して締結した契約（以下総称して｢利用契約等｣といいます。）の全部を解除することができます。<br />
                        （1）	利用契約等の相手方の代理人若しくは当該契約締結を媒介した者<br />
                        （2）	利用者の取締役、監査役、従業員その他の構成員、主要な株主、取引先、顧問、その他アドバイザー<br />
                        2.	本条に基づき利用契約等を解除した場合、当社は当該解除により利用者に生じた損害賠償責任を負いません。<br />
                        3.	本条に基づき利用契約等を解除した場合には、当該利用契約等の全ての規定（契約終了後も存続する旨定められている規定を含む。）は失効するものとします。<br />
                        <br />
                        第15条	（損害賠償）<br />
                        当社は、本件利用者による本件サービスの利用に関連して、当社の責に帰すべき事由により本件利用者に損害が発生した場合は、当該本件利用者が現実に被った直接かつ通常の損害を賠償するものとします。但し、当社の損害賠償の額は、当該損害の原因となった本件サービスの対価の１か月分を上限とします。<br />
                        <br />
                        第16条	（準拠法及び裁判管轄）<br />
                        本利用規約の準拠法は日本法とします。次条の定めにも拘らず、本件利用者と当社の間で紛争が生じた場合は、訴額に応じて東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。<br />
                        <br />
                        以上<br />
                        <br />
                        令和3年11月30日制定
                      </p>
                    </div>
                  </div>
                  <div class="form-function">
                    <input
                      type="checkbox"
                      v-model="state.registerUser.termsOfService"
                      class="form-input-check"
                      id="terms-of-service"
                    />
                    <label for="terms-of-service">
                      私は<a :href="host + '/ジッセン!DX_利用規約.pdf'" target="_blank">利用規約</a
                      >に同意します<sup class="type-required">*</sup>
                    </label>
                  </div>
                </template>
                <template v-if="!state.verification">
                  <div class="form-function border-t border-t--decoration">
                    <input
                      type="checkbox"
                      v-model="state.registerUser.is_receive_mail"
                      class="form-input-check"
                      id="delivery"
                    />
                    <label for="delivery">
                      株式会社アイレップからの有益な情報メールを受け取る
                    </label>
                  </div>
                </template>
                <template v-else>
                  <p
                    class="delivery-text"
                    v-if="state.registerUser.is_receive_mail"
                  >
                    株式会社アイレップからの有益な情報メールを受け取る
                  </p>
                  <p v-else class="delivery-text">
                    株式会社アイレップからの有益な情報メールを受け取らない
                  </p>
                </template>
              </form>
            </section>
          </div>

          <div
            v-if="state.verification"
            class="border-t border-t--decoration"
          >
            <section>
              <common-block-title
                title="ご契約内容確認"
                sub-title=""
                class-list="ja"
              />
              <div class="apply-register-form">
                <label class="form-function">
                  <div class="form-input-label pt-0">
                    契約期間
                  </div>
                  <div class="w-full">
                    <p>代金支払い日から1ヶ月が経過した日まで</p>
                  </div>
                </label>

                <label class="form-function">
                  <div class="form-input-label pt-0">
                    契約更新
                  </div>
                  <div class="w-full">
                    <p>毎月自動更新</p>
                  </div>
                </label>

                <label class="form-function">
                  <div class="form-input-label pt-0">
                    支払い方法
                  </div>
                  <div class="w-full">
                    <p>クレジットカード払い</p>
                  </div>
                </label>

                <label class="form-function">
                  <div class="form-input-label pt-0">
                    支払い予定日
                  </div>
                  <div class="w-full">
                    <p>{{state.next_payment_date}}</p>
                  </div>
                </label>

                <label class="form-function">
                  <div class="form-input-label pt-0">
                    商品等の提供方法
                  </div>
                  <div class="w-full">
                    <p>WEBブラウザでのダウンロード</p>
                  </div>
                </label>

                <label class="form-function">
                  <div class="form-input-label pt-0 white-space-normal">
                    商品等の引き渡し時期（日数）
                  </div>
                  <div class="w-full">
                    <p>ダウンロードによる即時提供</p>
                  </div>
                </label>

                <label class="form-function">
                  <div class="form-input-label pt-0">
                    返品の取扱条件
                  </div>
                  <div class="w-full">
                    <p>デジタルコンテンツの為、一度支払われた料金は、利用規約に別段の定めがない限り、返金されません</p>
                  </div>
                </label>

                <label class="form-function">
                  <div class="form-input-label pt-0">
                    返品期限
                  </div>
                  <div class="w-full">
                    <p>プレミアム会員のご契約は代金支払日から１ヶ月が経過した日までとなります</p>
                  </div>
                </label>

                <label class="form-function">
                  <div class="form-input-label pt-0">
                    解約方法
                  </div>
                  <div class="w-full">
                    <p>
                      ログイン後アカウント情報ページよりご自身で行えます<br>
                      毎月の代金支払日までに会員契約の解約手続を完了しない場合には翌月度も自動更新となります
                    </p>
                  </div>
                </label>
              </div>
            </section>
          </div>

          <div class="price-plane">
            <div class="block-title">
              <p>料金プラン</p>
              <div class="plan-name">
                <p class="plan-name-ja">プレミアム</p>
                <p class="plan-name-en">PREMIUM</p>
              </div>
            </div>
            <div class="plan-desc">
              <p class="plan-license">
                <icon
                  name="person-icon"
                  class="person-icon"
                />1ユーザーライセンス
              </p>
              <p class="plan-value">
                ¥3,300<span class="unit">/月（税込）</span>
              </p>
              <p class="pay-plan">
                次のお支払いはご登録いただいくクレジットカードにて、 {{state.next_payment_date}} に予定されています
              </p>
            </div>
          </div>
          <div class="submit-area">
            <button
              v-if="state.verification"
              class="submit-area-button submit-area-button--cancel"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              v-if="!state.verification"
              class="submit-area-button submit-area-button--save"
              :disabled="!state.registerUser.termsOfService"
              @click="submit"
            >
              NEXT
            </button>
            <button
              v-else
              class="submit-area-button submit-area-button--save"
              :disabled="!state.registerUser.termsOfService"
              @click="entry"
            >
              Save
            </button>
          </div>
        </div>
      </panel-body>
    </div>
    <form name="payService" :action="state.response.post_url" method="POST">
      <input type="hidden" name="merchant_id" :value="state.response.merchant_id"/>
      <input type="hidden" name="service_id" :value="state.response.service_id" />
      <input type="hidden" name="cust_code" :value="state.response.cust_code" />
      <input type="hidden" name="success_url" :value="state.response.success_url" />
      <input type="hidden" name="cancel_url" :value="state.response.cancel_url" />
      <input type="hidden" name="error_url" :value="state.response.error_url" />
      <input type="hidden" name="pagecon_url" :value="state.response.pagecon_url" />
      <input type="hidden" name="request_date" :value="state.response.request_date" />
      <input type="hidden" name="sps_hashcode" :value="state.response.sps_hashcode"/>

      <!-- 再契約 -->
      <input v-if="typeof state.response.order_id !== 'undefined'" type="hidden" name="order_id" :value="state.response.order_id">
      <input v-if="typeof state.response.item_id !== 'undefined'" type="hidden" name="item_id" :value="state.response.item_id">
      <input v-if="typeof state.response.amount !== 'undefined'" type="hidden" name="amount" :value="state.response.amount">
      <input v-if="typeof state.response.pay_type !== 'undefined'" type="hidden" name="pay_type" :value="state.response.pay_type">
      <input v-if="typeof state.response.service_type !== 'undefined'" type="hidden" name="service_type" :value="state.response.service_type">
      <input v-if="typeof state.response.pay_method !== 'undefined'" type="hidden" name="pay_method" :value="state.response.pay_method"> 
      <input v-if="typeof state.response.tax !== 'undefined'" type="hidden" name="tax" :value="state.response.tax"> 
    </form>
  </main>
</template>

<script>
import { defineComponent, reactive, computed, nextTick, watch, onMounted } from "vue";
import { useStore } from "vuex";
import http from "@/utils/http";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import TabNation from "./components/tabNation.vue";
import licenseInfomation from "@/views/apply/components/licenseInfomation.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import { useRoute, useRouter } from "vue-router";
import { ElSelect } from "element-plus";
import { ElOption } from "element-plus";

export default defineComponent({
  name: "applyInput",

  components: {
    contentPageHeader,
    CommonPageTitle,
    TabNation,
    licenseInfomation,
    CommonBlockTitle,
    PanelBody,
    ElSelect,
    ElOption,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const host = computed(() => window.location.protocol + '//' + window.location.host);

    // api 取得
    http
      .get(
        `/api/temporary-users/${route.query.token}/validate?email=${encodeURIComponent(route.query.email)}`
      )
      .then((res) => {
        console.log("成功", res.data);
      })
      .catch((error) => {
        console.log("エラー:", error);
        router.push({
          name: "notfound",
          params: { catchAll: route.query.token },
        });
      });

    const state = reactive({
      verification: false,
      errors: [],
      occupation: [],
      next_payment_date: "",

      registerUser: {
        company_name: "",
        name: "",
        name_kana: "",
        email: route.query.email,
        token: route.query.token,
        password: "",
        industry_type_id: "",
        termsOfService: false,
        is_receive_mail: false,
      },
      response: {
        post_url: "",
        merchant_id: "",
        service_id: "",
        cust_code: "",
        success_url: "",
        cancel_url: "",
        error_url: "",
        pagecon_url: "",
        request_date: "",
        sps_hashcode: "",
        order_id: "",
        item_id: "",
        amount: "",
        pay_type: "",
        service_type: "",
        pay_method: "",
        tax: "",

      },
    });

    // 職種一覧 api 取得
    http
      .get("/api/occupations")
      .then((res) => {
        console.log("成功", res.data);
        state.occupation = res.data;
      })
      .catch((error) => {
        console.log("エラー:", error);
      });

    // お支払日
    http
      .get(`/api/temporary-users/${state.registerUser.token}/next-payment-date`)
      .then((res) => {
        const yy = res.data.next_payment_date.substr(0, 4);
        const mm = res.data.next_payment_date.substr(5, 2);
        const dd = res.data.next_payment_date.substr(8, 2);
        state.next_payment_date = `${yy}/${mm}/${dd}`;
      })
      .catch((error) => {
        console.log("エラー:", error);
      });

    // 仮登録ユーザー情報
    http
      .get(`/api/temporary-users/${state.registerUser.token}`)
      .then((res) => {
        console.log("成功", res.data);
        state.registerUser.company_name = res.data.company_name;
        state.registerUser.name = res.data.name;
        state.registerUser.name_kana = res.data.name_kana;
        state.registerUser.industry_type_id = res.data.industry_type_id;
        state.registerUser.is_receive_mail = Boolean(res.data.is_receive_mail);
      })
      .catch((error) => {
        console.log("エラー:", error);
      });

    const isLogin = computed(() => store.state.user.isLogin);

    window.scrollTo(0, 0);

    const cancel = () => {
      state.verification = false;
      router.go(-1);
    };

    const submit = () => {
      console.log(state.registerUser);

      http
        .post(
          `/api/temporary-users/${state.registerUser.token}/premium/register/validate`,
          {
            name: state.registerUser.name,
            name_kana: state.registerUser.name_kana,
            company_name: state.registerUser.company_name,
            email: state.registerUser.email,
            password: state.registerUser.password,
            industry_type_id: state.registerUser.industry_type_id,
            is_receive_mail: state.registerUser.is_receive_mail ? "1" : "0",
          }
        )
        .then(() => {
          state.errors = [];
          window.history.pushState(null, null, "/apply/input/confirm");
          document.getElementById("app").scrollIntoView();

          state.verification = true;
        })
        .catch((error) => {
          document.getElementById("app").scrollIntoView();
          console.log("エラー:", error.response.data.errors);
          if (error.config && error.response && error.response.status === 400) {
            state.errors = error.response.data.errors;
          }
        });
    };
    const entry = () => {
      console.log("entry");
      console.log(state.registerUser);
      http
        .post(
          `/api/temporary-users/${state.registerUser.token}/premium/register`,
          {
            name: state.registerUser.name,
            name_kana: state.registerUser.name_kana,
            company_name: state.registerUser.company_name,
            email: state.registerUser.email,
            password: state.registerUser.password,
            industry_type_id: state.registerUser.industry_type_id,
            is_receive_mail: state.registerUser.is_receive_mail ? "1" : "0",
          }
        )
        .then((res) => {
          state.response.post_url = res.data.post_url;
          state.response.merchant_id = res.data.sbps_parameters.merchant_id;
          state.response.service_id = res.data.sbps_parameters.service_id;
          state.response.cust_code = res.data.sbps_parameters.cust_code;
          state.response.success_url = res.data.sbps_parameters.success_url;
          state.response.cancel_url = res.data.sbps_parameters.cancel_url;
          state.response.error_url = res.data.sbps_parameters.error_url;
          state.response.pagecon_url = res.data.sbps_parameters.pagecon_url;
          state.response.request_date = res.data.sbps_parameters.request_date;
          state.response.sps_hashcode = res.data.sbps_parameters.sps_hashcode;
          state.response.order_id =res.data.sbps_parameters.order_id;
          state.response.item_id =res.data.sbps_parameters.item_id;
          state.response.amount =res.data.sbps_parameters.amount;
          state.response.pay_type =res.data.sbps_parameters.pay_type;
          state.response.service_type =res.data.sbps_parameters.service_type;
          state.response.pay_method =res.data.sbps_parameters.pay_method;
          state.response.tax =res.data.sbps_parameters.tax;
          console.log(state.response);
          nextTick(() => {
            document.payService.submit();
          });
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          if (error.config && error.response && error.response.status === 400) {
            state.errors = error.response.data.errors;
          }
        });
    };

    const passwordVerification = (el) => {
      const secret = "●";
      return secret.repeat(el.length);
    };

    onMounted(async () => {
      if (isLogin.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isLogin,
      async () => {
        if (isLogin.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      state,
      cancel,
      submit,
      passwordVerification,
      entry,
      URL,
      host,
    };
  },
});
</script>

<style lang="scss" scoped>
.hdg-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(lg) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include mq(md) {
    display: block;
  }
}
.content-body {
  position: relative;
  margin-top: -221px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;
  @include mq(md) {
    margin-top: -590px;
  }
  @include mq(sm) {
    margin-top: -100px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.apply-register-form {
  position: relative;
  padding-top: 65px;
  &.edit-psaaword {
    margin-top: 115px;
    @include mq(sm) {
      margin-top: 60px;
    }
  }
}

.terms-of-service-content {
  position: relative;
  overflow-y: auto;
  height: 260px;
  padding: 30px;
  background-color: #f0f3f8;
  .title {
    font-size: 20px;
  }
  .text {
    margin-top: 20px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f0f3f8;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary;
  }
}

.price-plane {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 65px;
  padding-top: 65px;
  border-top: 1px solid $black;

  @include mq(lg) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.plan-name {
  margin-top: 20px;
}
.plan-name-ja {
  font-size: 12px;
  color: rgba(89, 88, 87, 0.8);
}
.plan-name-en {
  font-family: Roboto Condensed;
  font-size: 40px;
}
.plan-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include mq(lg) {
    align-items: flex-start;
    margin-top: 20px;
  }
}
.plan-license {
  .person-icon {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    vertical-align: baseline;
  }
}
.plan-value {
  margin-top: 10px;
  font-family: Roboto Condensed;
  font-size: 40px;
  color: $secondary;
  .unit {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: 18px;
    color: $dark-grey;
  }
}
.pay-plan {
  margin-top: 1em;
  color: #02daa6;
}
.delivery-text {
  margin-top: 50px;
  @include mq(sm) {
    margin-top: 30px;
  }
}
.error-message {
  margin-top: 1em;
}
.white-space-normal {
  white-space: normal;
}
</style>
