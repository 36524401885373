<template>
  <div class="plan-price-info">
    <div class="plan-price-info-name">
      <template v-if="licenseType === 'premium'">
        <p class="plan-name-ja">プレミアム</p>
        <p class="plan-name-en">PREMIUM</p>
      </template>
      <template v-else>
        <p class="plan-name-ja">ビジネス</p>
        <p class="plan-name-en">BUSINESS</p>
      </template>
    </div>
    <div class="plan-price-info-value">
      <p class="plan-license">
        <icon name="person-icon" class="person-icon" />1ユーザーライセンス
      </p>
      <p v-if="licenseType === 'premium'" class="plan-value">
        ¥3,300<span class="unit">/月（税込）</span>
      </p>
      <p v-else class="plan-value">
        ¥3,300<span class="unit">/月（税込）</span>
      </p>
    </div>
  </div>
  <div v-if="licenseType === 'premium'" class="premium-free-term-container">
      <p class="premium-free-term">1週間無料</p>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
export default defineComponent({
  name: "licenseInfomation",

  components: {},
  props: ["licenseType"],
  setup() {
    const state = reactive({});
    return { state };
  },
});
</script>

<style lang="scss" scoped>
.plan-price-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.plan-price-info-name {
  .plan-name-ja {
    font-size: 12px;
    color: rgba(89, 88, 87, 0.8);
  }
  .plan-name-en {
    font-family: Roboto Condensed;
    font-size: 40px;
  }
}
.plan-price-info-value {
  padding-left: 65px;
  border-left: 0.5px solid #0f0f11;
  @include mq(sm) {
    position: relative;
    margin-top: 65px;
    padding-left: 0;
    border: none;
    &::before {
      content: "";
      width: 30px;
      height: 1px;
      position: absolute;
      top: -30px;
      left: 0;
      background-color: $black;
    }
  }
  .plan-license {
    .person-icon {
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      vertical-align: baseline;
    }
  }
  .plan-value {
    margin-top: 10px;
    font-family: Roboto Condensed;
    font-size: 40px;
    color: $secondary;
    .unit {
      font-family: "Roboto", "Noto Sans JP", sans-serif;
      font-size: 18px;
      color: $dark-grey;
    }
  }
}
.premium-free-term-container {
  text-align: right;
  margin-top: 10px;

  @include mq(sm) {
    text-align: left;
  }

  .premium-free-term {
    font-size: 18px;
    font-weight: 700;
    color: #02daa6;
  }
}
</style>